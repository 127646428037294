@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* navigation */
header nav{
  z-index:9999;
}
header .nav-link{
  font-size: 20px;
  font-weight: 500;
}

header.light .nav-link{
  color:#fff;
}

header.dark .nav-link{
  color:#000;
}

header .contact{
  border-bottom:solid 2px #000;
}

header.light .contact{
  border-bottom:solid 2px #fff;
}

header.header-dark{
  background:#000;
}

.navbar-toggler{
  border:none;
}
.navbar-toggler:focus{
  box-shadow:none;
}
/* Define the shape and color of the hamburger lines */
.navbar-toggler span {
  display: block;
  height: 3px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}
header.dark .navbar-toggler span {
  background-color: #4f4f4f;
}

header.light .navbar-toggler span {
  background-color: #fff;
}

/* top line needs a little padding */
.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}

/**
* Animate collapse into X.
*/

/* top line rotates 45 degrees clockwise and moves up and in a bit to close the center of the X in the center of the button */
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}
/* center line goes transparent */
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}
/* bottom line rotates 45 degrees counter clockwise, in, and down a bit to close the center of the X in the center of the button  */
.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg) ;
}

/**
* Animate collapse open into hamburger menu
*/

/* top line moves back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg) ;
}
/* middle line goes back to regular color and opacity */
.navbar-toggler span:nth-child(2) {
  opacity: 1;
}
/* bottom line goes back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg) ;
}

/* work page */
.work{
  font-size: 20px;
}
.work-fontColor1{
  color: #021626;
}
.work-fontColor2{
  color: #01234d;
}
.mainTitle {
  font-size:2rem;
}
@media (min-width: 390px) {  
  .mainTitle {font-size:3rem;}
  .workFooter {font-size:.8rem;}
}
@media (min-width: 820px) {  
  .mainTitle {font-size:3rem;}
  .workFooter {font-size:1rem;}
}
@media (min-width: 1200px) {  
  .mainTitle {font-size:5rem;}
  .workFooter {font-size:1.3rem;}
}
.altbg{
  background:#d9f4f7;
}
.altbg2{
  background:#f9b586;
}
.altbg3{
  background:#01234d;
}
.altbg3Font{
  color:#fff;
}
.altbg3Font a{
  color:#fff;
}
.altbg4{
  background:#c1d2e9;
}
.altbg4Font{
  color:#01234d;
}
.altbg4 .border-bottom, .altbg4 .border-left{
  border-color:#c1d2e9 !important;
}
.altbg5{
  background:#c24f4b;
}
.altbg5Font{
  color:#ffffff;
}
.altbg6{
  background:#215493;
}
.altbg6Font{
  color:#ffffff;
}
@media (min-width: 390px) {  
  .altbg4 .border-left{
    border-left:none;
  }
}
@media (min-width: 820px) {  
  .altbg4 .border-left{
    border-left:1px solid;
  } 
}
.mainbg{
  background:#ffffff;
  margin:auto;
  max-width: calc(100vw - 100px);
}
.subTitle{
  font-weight: 500;
}
.mainTitle{
  font-weight: bold;
}
.mainDes{
  font-weight: normal;
}
.workText2{
  color:#c3272b;
}
.workText2:hover{
  color:#c3272b;
}
.workText3{
  color:blue;
}
.workText3:hover{
  color:blue;
}
.workBold{
  font-weight: bold;
}
.workBold2{
  font-weight: 600;
}
.workBold3{
  font-weight: bold;
  font-size: 1.5em;
}
.workBold4{
  font-weight: 600;
  font-size: 1.5em;
}
.workBold4{
  font-weight: bold;
  font-size: 1.2em;
}

.workBoldItalic{
  font-weight: 600;
  font-style: italic;
}

.workItalic{
  font-style: italic;
  font-weight: normal;
}

.worklink{
  color: #19b4b9;
}

.worklink2{
  color: #000;
  text-decoration:none;
}

.worklink2:hover{
  color: #000;
}

.container-fluid{
  padding:0;
}

.EnlargedImage__container{
  width:100%;
}
.EnlargedImage__enlarged-container{
  z-index:9999 !important;
}

.roundImg .EnlargedImage__Image{
  border-radius: 15px;
}

.workFooter button{
  border:none;
  background:none;
  color:#000;
}

@media (min-width: 390px) {  
  .banner{
    width:12px;
    height:570px;
  }  
  .removeFormText_phone{
    display: inline;
  }
  .removeFormText_desktop{
    display: none;
  }
}
@media (min-width: 820px) {  
  .banner{
    width:50px;
    height:1202px;
  }
  .removeFormText_phone{
    display: none;
  }
  .removeFormText_desktop{
    display: inline;
  }  
}

.banner.leftbanner{
  background:#01234d;
  border-radius: 0 10px 10px 0;
}

.banner.rightbanner{
  background:#b51a19;
  border-radius: 10px 0 0 10px;
}

@media (min-width: 390px) {  
  .banner2{
    width:12px;
  }
  .prototype_title{
    transform: rotate(0deg);
    transform-origin: center;
  }
}
@media (min-width: 820px) {  
  .banner2{
    width:50px;
  }
  .prototype_title{
    transform: rotate(-90deg);
    transform-origin: center;
  }
}

.banner2{
  background:#f9b586;
  height:100%;
}

.banner2.leftbanner2{
  border-radius: 0 10px 10px 0;
}

.banner2.rightbanner2{
  border-radius: 10px 0 0 10px;
}

.leftBannerWide{
  background:#b51a19;
  border-radius: 0 20px 20px 0;
  color:#fff;
}

.leftBannerWideContainerFill
{
  background:#b51a19;
}

.rightBannerWide{
  background:#258551;
  border-radius: 20px 0px 0px 20px;
  color:#fff;
}

.rightBannerWideContainerFill
{
  background:#258551;
}

.roundCol{
  border-radius: 20px;
}

@media (min-width: 390px) {  
  .lgImg {display:none;}
  .smImg {display:inline;}
}
@media (min-width: 820px) {  
  .smImg {display:none;}
  .lgImg {display:inline;}
}

.overlapimg{
  position: relative;
  width:75%;
}
.overlapimg-right{
  position: relative;
  float: right;
  top:-25px;
}
.redFont
{
  color:#b51a19;
}
.redFont2{
  color:#db1d1b;
}
.redFontUnderline
{
  color:#db1d1b;
  text-decoration: underline;
}
.greenFont
{
  color:#00ae51;
}
.breaker{
  height: 80px;
}

/* Context Container */
@media (min-width: 390px) {  
  .contextMenu {display:none;}
}
@media (min-width: 820px) {  
  .contextMenu {display:inline;}
}
.contextMenu{
  min-width: 50px;
  border-radius: 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.6);
  opacity: 0.6;
  position: fixed;
  right:10px;
  z-index: 99999;
}
.contextMenuContainer{
  overflow: hidden;
}
.contextMenuContainer.show{
  min-width: 50px;
}
.contextMenuContainer.hide{
  width:0px;
  height:0px;
}
.contextMenu a{
  color:#000;
}
.contextMenu button{
  border:none;
  background:none;
}
.contextMenu .contextMenuButton.show{
  display:inline;
}
.contextMenu .contextMenuButton.hide{
  display:none;
}
.nda{
  color:#969696;
}
/* footer */
footer{
  color: #969696;
}
footer.light{
  background: #000;
}
footer .talk{
  font-weight: bold;
}
footer.dark a, footer.dark .talk{
 color:#000;
}
footer.dark .contactinfo{
  border-bottom:2px solid #000;
}
footer.light a, footer.light .talk{
  color:#fff;
}
footer.light .contactinfo{
  border-bottom:2px solid #fff;
}
footer p{
  margin-bottom: 0;
  padding-bottom:1rem;
}
footer .nav-link:hover{
  color:#fff;
}
footer.dark .nav-link:hover{
  color:#000;
}
@media (min-width: 390px) {  
  footer {font-size:.9rem;}
  footer .smImg { font-size:1.3rem;}
  .talk {font-size:2rem; }
}
@media (min-width: 820px) {  
  footer {font-size:1.3rem;}
  .talk {font-size:3.8rem; }
}
 /*about*/
.about-cover{
  position: absolute;
  top:0;
  z-index:1000;
}
.about-cover .container .row{
  height: 100vh;
}
.about-cover .title{
  font-size:20px;
  color:#fff;
}
.about-cover .resumelink a{
  font-size:20px;
  color:#fff;
  font-weight: bold;
}
.about-text{
  margin-top:100vh;
}
.about-philosophy{
  font-size:30px;
  font-weight: bold;
}
@media (min-width: 390px) {  
  .workBold4 {text-align:center}
}
@media (min-width: 820px) {  
  .workBold4 {text-align:left}
}
/*home*/
.home{
  background:#000;
}
.home .number{
  font-size:2rem;
}
.home .hashtag{
  color:#0052b2
}
.home .hashtag_m{
  color:#ADD8E6
}
.home-cover{
  position: absolute;
  top:0;
  z-index:1000;
}
.home-cover .container .row{
  height: 100vh;
}
.home-cover .title{
  font-weight: bold;
  color:#fff;
}
.home-cover .subtitle{
  font-size: 1.3rem;
  color:#fff;
}
.home-works{
  margin-top:calc(100vh - 126px);
}
.home-work .container-fluid .mx-0.row, .home-work .container-fluid .home-work-text-row{
  height: 100vh;
}
.home-work{
  background-size: cover;
  background-attachment: fixed;
}
.home-work-m{
  background-attachment:local;
}
.home-work .title{
  font-size: 5rem;
  font-weight:bold;
  overflow-wrap: break-word;
}
.home-work a{
  color:#000;
}
.home-work-text-row_m, .home-work-text-row_m a{
  color:#fff;
}
.scroll_down{
    position: absolute;
    left:50%;
    bottom: 0px;
    transform: translate(-50%, -50%);
}
.scroll_down button{
  background:none;
  border:none;
}
/* PasswordComponent.css */
.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Make the container take up the full height of the viewport */
  text-align: center;
}

.password-container form {
  margin-top: 20px;
}

@media (min-width: 390px) {  
  .home-cover .title {font-size: 2rem}
  .lgWork {display:none}
  .smWork {display:block}
}
@media (min-width: 820px) {  
  .home-cover .title {font-size: 5rem}
  .lgWork {display:block}
  .smWork {display:none}
}